<template>
  <div v-if="!preview" class="flex flex-col gap-4">
    <h3 class="font-medium">Create Survey</h3>
    <section class="flex flex-col gap-8">
      <form
        class="bg-white rounded-lg overflow-hidden shadow-sm h-auto border p-8 xl:relative"
        @submit.prevent="previewSurvey"
        v-for="(question, idx) in formData.questions"
        :key="idx"
      >
        <div>
          <div class="flex flex-wrap mb-6">
            <div class="w-full">
              <label
                class="block text-gray-500 text-xs font-bold mb-2"
                for="question"
              >
                Question
              </label>
              <div class="flex justify-between gap-4">
                <input
                  class="appearance-none block w-full h-14 text-gray-700 border border-gray-300 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-400"
                  id="question"
                  type="text"
                  maxlength="200"
                  placeholder="Type here"
                  v-model="question.question"
                />
                <button
                  class="text-red mt-2 flex justify-center items-center rounded-lg border border-red w-12 h-10 hover:bg-red hover:text-white disabled:opacity-25"
                  @click.prevent="deleteQuestion(idx)"
                  :disabled="formData.questions.length === 1"
                >
                  <span class="material-icons ml-6">delete_outlined</span>
                </button>
              </div>
              <p class="text-gray-600 text-xs">
                Type your question here between 1 to 200 characters
              </p>
            </div>
          </div>
          <div class="grid grid-cols-5 gap-4 mb-6 w-full">
            <div class="w-full" v-for="(option, index) in options" :key="index">
              <input
                class="w-full outline-none p-4 border border-textGray-700 rounded-md placeholder-black"
                :id="'option-' + idx + '-' + index"
                :placeholder="option"
                disabled
              />
            </div>
          </div>
        </div>
      </form>

      <div class="w-full">
        <button
          class="bg-transparent w-full border-grey border h-12 font-light text-gray-700 rounded-lg"
          type="submit"
          @click="addQuestion()"
        >
          + Add New Question
        </button>
      </div>
      <div class="flex flex-col gap-8 pointer-events-none opacity-40">
        <h1 class="font-semibold text-xl">Your feedback here</h1>
        <div>
          <label
            class="block text-gray-500 text-xs font-bold mb-2"
            for="question"
          >
            What was the best highlight of your visit (If you happen
            to know who helped it make better)?
          </label>
          <input
            class="appearance-none block w-full h-14 text-gray-700 border border-gray-300 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-400"
            id="question"
            type="text"
            placeholder="Type here"
          />
          <p class="text-gray-600 text-xs">
            Type your feedback here between 1 to 200 characters
          </p>
        </div>
        <div>
          <label
            class="block text-gray-500 text-xs font-bold mb-2"
            for="question"
          >
            Is there anything we can do to improve?
          </label>
          <input
            class="appearance-none block w-full h-14 text-gray-700 border border-gray-300 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-400"
            id="question"
            type="text"
            placeholder="Type here"
          />
          <p class="text-gray-600 text-xs">
            Type your feedback here between 1 to 200 characters
          </p>
        </div>
      </div>
      <div class="w-100">
        <button
          class="bg-teal float-right h-12 w-34 font-light text-white rounded-lg"
          :class="{
            'opacity-50 cursor-not-allowed':
              formData?.questions[0]?.question === '',
          }"
          :disabled="formData?.questions[0]?.question === ''"
          @click="previewSurvey"
        >
          Preview
        </button>
      </div>
    </section>
  </div>
</template>

<script setup>
import { ref, watch, inject } from "vue";
import { useRouter } from "vue-router";

const toast = inject("toast");

const currentIndex = ref(null);
const router = useRouter();

const preview = ref(false);

const formData = ref({
  questions: [
    {
      question: "",
    },
  ],
});

const options = ref([
  "Strongly Disagree",
  "Disagree",
  "Neutral",
  "Agree",
  "Strongly Agree",
]);

const addQuestion = async () => {
  formData.value.questions.push({
    question: "",
  });
};
function deleteQuestion(index) {
  formData.value.questions.splice(index, 1);
}

const previewSurvey = () => {
  for (let i = 0; i < formData.value.questions.length; i++) {
    if (formData.value.questions[i].question === "") {
      toast.show(`Please Add Question no:${i + 1}`);
      return;
    }
  }
  router.push({ name: "hq-survey-preview" });
  localStorage.setItem("survey-questions", JSON.stringify(formData.value));
};

watch(formData, () => {
  currentIndex.value = formData.value.questions.length - 1;
});
</script>

<style scoped>
.form-disabled {
  pointer-events: none;
  opacity: 0.4;
}
</style>
